import {
  AmplitudeScript,
  Environment,
  EnvironmentIdentifier,
  GoogleTagManagerScript,
  HotjarScript,
  LeanScript,
  prypcoEnv,
} from '@aw/prypco/environment';

export const environment: Environment = {
  ...prypcoEnv,
  production: true,
  testing: false,
  noIndex: false,
  dev: false,
  externalScripts: {
    amplitude: new AmplitudeScript(
      'b7eb85790636b15a5bfa1ebf201dfdf6',
      {
        defaultTracking: {
          attribution: false,
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false,
        },
        optOut: false,
      },
      false,
      true,
    ),
    googleTagManager: new GoogleTagManagerScript('GTM-52T4HPT', true),
    hotjar: new HotjarScript(5003603, 6, true),
    lean: new LeanScript('b72ca4b6-3274-4637-b9b8-e356bd99709e', false),
  },
  featureFlags: {
    notifications: false,
    agencyDetails: true,
    newBankOffers: true,
    mortgagePartners: false,
    devModule: false,
    devErrorInterceptor: false,
    blocks: {
      comingSoon: false,
      portal: true,
      backOffice: true,
      devPanel: false,
      payByCard: true,
      payInstantTransfer: true,
    },
    listing: {
      backOffice: true,
    },
    services: {
      backOffice: true,
      rnpl: true,
      goldenVisa: true,
    },
    mortgage: {
      allowReaChannelFederation: true,
    },
  },
  promos: {
    mortgage: false,
  },

  identifiers: {
    [EnvironmentIdentifier.MapBoxKey]:
      'pk.eyJ1IjoicHJ5cHRvIiwiYSI6ImNsZmpvbmF5bzAzMHczdHBjaHhzdHN6NHUifQ.Odt7HXtV-hIbe9jswzzw0A',
  },
};
