import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Hammer from 'hammerjs';
import { IConfig, provideEnvironmentNgxMask } from 'ngx-mask';

import { RoutingHistoryService } from '@aw/shared/services/routing-history';

import {
  ENV,
  Environment,
  msalApp,
  msalGuardConfig,
  msalInterceptorConfig,
} from '@aw/prypco/environment';
import { DialogServiceModule } from '@aw/prypco/services/dialog';
import { ReferenceDataService } from '@aw/prypco/services/reference-data';
import {
  AmplitudeNoopService,
  AmplitudeService,
  AmplitudeServiceImpl,
} from '@aw/prypco/services/script-manager';
import { SnackBarModule } from '@aw/prypco/services/snackbar';
import { AuthFacade, AuthStateModule } from '@aw/prypco/state/auth';
import { EventStateModule } from '@aw/prypco/state/events';
import { SocketStateModule } from '@aw/prypco/state/socket';
import { SplashScreenStateModule } from '@aw/prypco/state/splash-screen';

import { environment } from '../environments/environment';
import { appInitializer } from './app-initializer.util';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@Injectable()
class HammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
    pan: { enable: false },
  };
}

const maskConfig: Partial<IConfig> = {};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SnackBarModule,
    DialogServiceModule,
    AppRoutingModule,
    MsalModule.forRoot(msalApp, msalGuardConfig, msalInterceptorConfig),
    // Start of NGRX
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AuthStateModule,
    SplashScreenStateModule,
    EventStateModule,
    SocketStateModule,
    // End of NGRX
    HammerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [
        MsalService,
        MsalBroadcastService,
        ReferenceDataService,
        AuthFacade,
      ],
      multi: true,
    },
    {
      provide: ENV,
      useValue: { ...environment },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {
      provide: AmplitudeService,
      useFactory: (env: Environment) =>
        env.externalScripts.amplitude.enabled
          ? new AmplitudeServiceImpl()
          : new AmplitudeNoopService(),
      deps: [ENV],
    },
    MsalService,
    MsalGuard,
    RoutingHistoryService,
    provideEnvironmentNgxMask(maskConfig),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(private amplitudeService: AmplitudeService) {}
}
