import {
  AmplitudeScript,
  GoogleTagManagerScript,
  HotjarScript,
  LeanScript,
} from './external-scripts.model';
import { PrypcoEnv } from './prypco-env.model';

export interface Environment extends PrypcoEnv {
  production: boolean;
  noIndex: boolean;
  testing: boolean;
  dev: boolean;
  featureFlags: FeatureFlags;
  promos: PromoFlags;
  identifiers: Partial<Record<EnvironmentIdentifier, string>>;
  externalScripts: {
    amplitude: AmplitudeScript;
    googleTagManager: GoogleTagManagerScript;
    hotjar: HotjarScript;
    lean: LeanScript;
  };
}

interface FeatureFlags {
  notifications: boolean;
  agencyDetails: boolean;
  newBankOffers: boolean;
  mortgagePartners: boolean;
  devModule: boolean;
  devErrorInterceptor: boolean;

  blocks: {
    comingSoon: boolean;
    portal: boolean;
    backOffice: boolean;
    devPanel: boolean;
    payByCard: boolean;
    payInstantTransfer: boolean;
  };
  listing: {
    backOffice: boolean;
  };
  services: {
    backOffice: boolean;
    goldenVisa: boolean;
    rnpl: boolean;
  };
  mortgage: {
    allowReaChannelFederation: boolean;
  };
}

interface PromoFlags {
  mortgage: boolean;
}

export enum EnvironmentIdentifier {
  MapBoxKey = 'MAP_BOX_KEY',
  LeanKey = 'LEAN_KEY',
}
